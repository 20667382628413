// Gatsby supports TypeScript natively!
import React from "react"
import {PageProps, Link, graphql} from "gatsby"

import Bio from "../components/bio"
import BlogLayout from '../components/bloglayout'
import Layout from '../components/layout'
import SEO from "../components/SEO"
import {rhythm} from "../utils/typography"

type Data = {
    site: {
        siteMetadata: {
            title: string
        }
    }
    allMarkdownRemark: {
        edges: {
            node: {
                excerpt: string
                frontmatter: {
                    title: string
                    date: string
                    description: string
                    hero: string
                }
                fields: {
                    slug: string
                }
            }
        }[]
    }
}

const BlogIndex = ({data, location}: PageProps<Data>) => {
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
        <Layout>
            <BlogLayout location={location} title={siteTitle}>
                <SEO title="All posts"/>
                <Bio location={location}/>
                {posts.map(({node}) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        <article key={node.fields.slug}>
                            <header>
                                <img src={node.frontmatter.hero} style={{marginBottom: 0}} />
                                <h3
                                    style={{
                                        marginBottom: rhythm(1 / 4),
                                        marginTop: 15
                                    }}
                                >
                                    <Link style={{boxShadow: `none`}} to={node.fields.slug}>
                                        {title}
                                    </Link>
                                </h3>
                                <small>{node.frontmatter.date}</small>
                            </header>
                            <section>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: node.frontmatter.description || node.excerpt,
                                    }}
                                />
                            </section>
                        </article>
                    )
                })}
            </BlogLayout>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        hero
                    }
                }
            }
        }
    }
`